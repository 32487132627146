.slider-detail-component{
    .service-widget-area{
        .service-widget{
            margin-bottom: 45px;
            .service-widget-title{
                padding-bottom: 18px;
                margin-bottom: 35px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    width: 57px;
                    height: 3px;
                    background: #fff;
                    z-index: 1;
                }
                &::before{
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    width: 40px;
                    height: 3px;
                    background: $primary_color;
                    z-index: 2;
                }
            }
            ul{
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;
                &:not(.recent-news) {
                    > li {
                      font-size: 14px;
                      position: relative;
                      margin-bottom: 11px;
                      line-height: 24px;
                      
                      a{
                        font-weight: 600;
                        color: #1b1d21;
                        display: inline-block;
                        position: relative;
                        transition: all 0.35s linear;
                        -webkit-transition: all 0.35s linear;
                        text-transform: uppercase;
                        padding-left: 15px;
                        font-size: 13px;
                        &:hover{
                            color: $primary_color;
                          }
                      }
                    }
                }
                li{
                    a{
                        &::before{
                            content: "";
                            position: absolute;
                            left: 0;
                            top: calc(50% - 2.5px);
                            width: 5px;
                            height: 5px;
                            background: $primary_color;
                            transition: all 0.3s linear;
                            -webkit-transition: all 0.3s linear;
                        }
                    }
                }
            }
            .widget-service-icon svg {
                fill: $primary_color;
                width: 18px;
                height: 18px;
                margin-right: 20px;
            }
        }
    }
    .service-rounded-img{
        border-radius: 5px;
    }
    img {
        height: auto;
        max-width: 100%;
    }
    .service-detail-quote {
        border-radius: 5px;
        padding: 50px 10px 50px 10px;
        .service-detail-quote-desc{
            padding: 28px 0px 25px 50px;
            border-style: solid;
            border-width: 0px 0px 0px 5px;
            border-color: $primary_color;
            display: flex;
            justify-content: space-between;
            h3{
                margin-bottom: 2px;
                color: #fff;
            }
            p{
                text-transform: uppercase;
                color: #fff;
                margin-bottom: 0;
            }
        }
    }
    h5{
        font-size: 24px;
    }
    
}

.services-section{
    .services-ot-heading{
        font-family: "Inter", sans-serif;
        span{
            position: relative;
            display: inline-block;
            color: #95A2AC;
            border-color: #95A2AC;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 6px;
            text-transform: uppercase;
        }
        .services-main-head{
            margin-bottom: 0;
            font-size: 36px;
        }
    }
    p{
        color: #1b1d21;
    }
}

.content-box{
    a{
        color: #000;
    }
}
.ot-button{
    margin-right: 40px;
}
@media screen and (max-width:991px) {
    .service-detail-quote-desc{
        display: block !important;
    }
    .ot-button{
        margin-top: 20px;
    }
}