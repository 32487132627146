a{
    text-decoration: none;
}
.error{
    color: red;
}
.space-10{
    width: 100%;
    height: 10px;
}
.space-6{
    width: 100%;
    height: 6px;
}
.space-40{
    width: 100%;
    height: 40px;
}
.space-5{
    width: 100%;
    height: 5px;
}
.space-90{
    width: 100%;
    height: 90px;
}



.globalsite-content{
    &::before{
        content: "";
        display: table;
        table-layout: fixed;
    }
}
.globalpage-header {
    width: 100%;
    height: 400px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    background: #1b1d21 center center no-repeat;
    background-size: cover;
    .globalinner {
        margin-top: -13px;
    }
    .globalflex-middle {
        display: flex;
        align-items: center;
        justify-content: center;
        .globalpage-title {
            font-size: 42px;
            color: #fff;
            margin-bottom: 0;
            flex: 1;
            padding: 10px 0;
        }
    }
    .globalbreadcrumbs {
        margin-bottom: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        li {
            display: inline-block;
            color: #fff;
            a{
                color: rgba(255, 255, 255, 0.6);
            }
            &::before{
                content: "";
                font-family: "Flaticon";
                font-size: 9px;
                color: rgba(255, 255, 255, 0.6);
                margin: -2px 8px 0;
                margin-left: 5px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
.globalnone-style {
    list-style: none;
    padding-left: 0;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 20px;
    color: #1b1d21;
}
.custom-height {
    height: 100vh;
  }

.ot-div{
    display: flex;
}


@media screen and (max-width:991px) {
    .mobile_logo img {
        width: 90px;
    }
}
@media screen and (max-width:768px) {
    .ot-div{
        display: unset;
    }
    .coporate-banner .icon-box-2{
        margin-left: unset;
    }
    .ot-div .ot-button{
        margin-bottom: 20px;
    }
}
