.field-holder {
    margin-bottom: 1.5em;
    position: relative;
}
.custom-input, .custom-label {
    height: 35px;
    width: 100%;
}
.custom-input {
    border: 0;
    border-bottom: 1px solid #cecede;
    font-size: 14px !important;
    outline: none;
    transition: .2s ease-in-out;
}
.custom-label {
    align-items: center;
    color: gray;
    display: flex !important;
    font-size: 16px !important;
    left: 0;
    position: absolute;
    top: 0;
    transition: .2s ease-in-out;
}
.contactformaddress-message {
    color: #55bb53;
    padding-bottom: 20px;
}
.contactaddress-textarea {
    border: none !important;
    border-bottom: 1px solid #cecede !important;
    font-size: 14px;
    height: 100px;
    width: 100%;
}
.product-detail-buttongroup {
    display: flex;
    float: right;
    width: 30%;
}
.login-register-btn {
    display: flex;
    justify-content: center;
}
.product-detail-buttongroup .product-detail-button {
    background: #55bb53;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    margin-left: 15px;
    padding: 7px;
    text-align: center;
    width: 100%;
}
.product-detail-button {
    margin-top: 15px !important;
    padding: 10px !important;
    width: 60% !important;
}

