.contact-page{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    
    padding-top: 61px;
    .space-110 {
        width: 100%;
        height: 110px;
    }
    .ot-heading {
        // font-family: $prmary_font;
      
           
            .is_highlight {
                position: relative;
                display: inline-block;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                color: #55bb53;
                margin-bottom: 10px;
                background: #55BB531A;
                padding: 2px 10px;
                border: 1px solid #55bb53;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px;
            }
      
        .main-head {
            margin-bottom: 0;
            font-size: 36px;
        }
    }
    .space-20 {
        width: 100%;
        height: 20px;
    }
    .space-2 {
        width: 100%;
        height: 2px;
    }
    p {
        margin: 0 0 20px;
    }
    .space-10 {
        width: 100%;
        height: 10px;
    }
    .icon-box{
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
    }
    .contact-iconb {
        display: flex;
        .icon-main {
            background: #55BB531A;
            width: 60px;
            height: 60px;
            font-size: 27px;
            line-height: 60px;
            border-radius: 50% 50% 50% 50%;
        }
        .content-box {
            // margin-left: 76px;
            margin-left: 26px;
            margin-top: 10px;
            max-width: 360px;
            a{
                color: #000;
                text-decoration: none;
            }
        }
    }
    .icon-box-2{
        &:hover{
            .icon-main{
                box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.15);
                -webkit-box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.15);
            }
        }
    }
   
}
.contact-form {
    // margin-top: -245px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
    .contactformaddress-main-div{
        padding: 60px 60px 60px 60px;
        background-color: #FFF;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 0 30px 0 rgb(0 0 0 / 12%);
    }
}
.custom-spinners{
    width: 20px !important;
    height: 20px !important;
    border-width: 3px !important;
    color: $primary_color;
    top: 10px;
    margin-top: 19px;
}
