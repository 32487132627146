.subscription-coporate-cta{
    position: relative;
    margin-top: -75px;
    margin-bottom: -75px;
    .container{
        max-width: 1350px;
        .subscription-coporate-icon-cta{
            background-color: $primary_color;
            padding: 65px 75px 65px 75px;
            border-radius: 5px 5px 5px 5px;
            box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.12);
            img{
                width: 140px;
            }
        }
        .subscription-coporate-cta-form{
            .subscription-team-form {
                justify-content: space-between;
                input{
                    width: 100%;
                    max-width: 400px;
                    margin-left: 10px;
                    background: transparent;
                    border-color: rgba(255, 255, 255, 0.6);
                    color: #fff;
                    border: 1px solid #dbdbdb;
                    padding: 11px 24px;
                    box-sizing: border-box;
                    outline: none;
                    font-size: 14px;
                    border-radius: 3px;
                }
            }
            .subscription-octf-btn{
                color: #0b2a41;
                background-color: #fff;
                margin-left: 10px;
                border-color: #55bb53;
                background: #fff;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                border-radius: 3px;
                padding: 14px 30px;
                line-height: 1.42857143;
                display: inline-block;
                margin-bottom: 0;
                text-decoration: none;
                text-transform: uppercase;
                white-space: nowrap;
                vertical-align: middle;
                font-family: "Inter", sans-serif;
                font-weight: 600;
                text-align: center;
                border: 1px solid transparent;
                outline: none;
                position: relative;
            }
        }
    }
   
}