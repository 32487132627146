.about-us-section{
    
    .aboutus-author {
        margin-left: 15px;
        h6 {
            color: #1b1d21;
            font-weight: 700;
            margin-bottom: 0;
            font-family: "Inter", sans-serif;
            line-height: 1.2;
            font-size: 20px;
        }
        
    }
    .about-ot-heading{
        > span.about-is_line:before {
            width: 45px;
            background: #95A2AC;
            content: ''; /* Ensures the pseudo-element has content */
            display: inline-block; /* Ensures the pseudo-element is inline */
            
        }
     
        .about-is_line{
            padding-left: calc(45px + 15px);
            color: #95A2AC;
            border-color: #95A2AC;
            font-size: 14px;
            font-weight: 600;
            // &::before{
            //     width: 45px;
            //     background: #95A2AC;
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     bottom: 50%;
            //     height: 1px;
            // }

        }
        .about-main-head{
            margin-bottom: 0;
            color: #1b1d21;
            font-weight: 700;
            line-height: 1.2;
            margin: 0 0 20px;
        }
    }

    @media (min-width: 1200px) {
        .about-img {
            margin: 0 -60px 0 0;
            box-shadow: 0 0 30px 0 rgb(0 0 0 / 12%);
        }
    }
}

