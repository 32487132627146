//about page start
.page-aboutus{
    background-color: #fff;
    height: 100%;
    font-family: "Open Sans", sans-serif;
    .main-banner {
        padding-bottom: 12rem;
        padding-top: 2rem;
        background-color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-family: "Open Sans", sans-serif;
        position: relative;
        z-index: 1;
        overflow: hidden;
        .innertext-area {
            border-radius: 0;
            border: none;
            background-color: transparent;
            padding: 15px 0;
        }
        .card{
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-clip: border-box;
            .mb-12{
                margin-bottom: 12px;
            }
        }
    }
}
.bg-color-dark {
    background-color: #1c1c1c;
}
.section-about--block {
    padding-top: 8.7rem;
    padding-bottom: 7.6rem;
    .about_content {
        position: relative;
        h4 {
            font-size: 1.25rem;
            color: rgba(255, 255, 255, .6);
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }
    .about_content--desc {
        font-size: 1rem;
        color: #fff;
    }
}
.pos-relative {
    position: relative;
}
.page-teams{
    margin-top: 50px;
}
.team-members {
    .card{
        border-radius: 25px;
        background-color: rgba(18, 136, 7, 0.5);
        border: none;
        color: #fff;
    }
    .card-title{
        font-size: 35px;
        font-weight: 600;
    }
}
@media (min-width: 992px) {
    .section-about--block{
        .about_content{
            margin-bottom: 3rem;
            padding-left: 2.6rem;
            h3 {
                font-size: 3.125rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .about_content--desc {
            padding-right: 2rem;
            padding-left: 4.2rem;
        }
    }
    .header-60 {
        font-size: 3.75rem;
        margin-bottom: 20px;
        font-weight: 700;
    }
}
@media (min-width: 768px) {
    .main-banner{
        .innertext-area {
            padding: 15px;  
        }
    }
    .section-about--block{
        .about_content {
                h4 {
                font-size: 1.375rem;
            }
        }
        .about_content--desc {
            font-size: 1.125rem;
        }
    }
}
@media (min-width: 1200px) {
    .main-banner {
        .title-large {
            font-size: 3.75rem;
            font-weight: 900;
            line-height: 4.375rem;
        }
    }
}


.page-services-detail{
    position: relative;
    z-index: 1;
    .page-header {
      color: #000;
      .cont {
          padding: 15px 0 50px;
      }
    }
    .sub-banner-content {
        color: #000;
        // font-family: $primary_font;
        .icon-thumb {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 1.2rem;
          i{
            // color: $ternary_color;
            font-size: 30px;
          }
        }
        .sub-heading-sm {
          font-size: 2.5rem;
          font-weight: 700;
          margin-bottom: 1.3rem;
        }
        .short-note {
          font-size: 1.05rem;
          line-height: 1.5rem;
          padding: 0;
          margin-bottom: 2rem;
        }
    }
    .color-primary {
      color: #21a462 !important;
    }
    .service-overview {
      background: #1c1c1c;
      color: #fff;
      padding: 4rem 3rem;
    //   font-family: $primary_font;
      .service-ov__title {
          font-size: 2.5rem;
          font-weight: 700;
          color: #fff;
          margin-bottom: 1rem;
      }
      .service-ov__desc {
          font-size: 1rem;
          line-height: 1.8rem;
      }
    }
    .btn-client-all {
      border-radius: 0;
    //   font-family: $primary_font;
      font-size: 1.5rem;
      font-weight: 600;
      color: #fff;
      position: relative;
      z-index: 1;
      padding: 0;
      -webkit-transition: all .3s ease-out;
      -o-transition: all .3s ease-out;
      transition: all .3s ease-out;
    }
    .moreless-button {
      color: black !important;
      font-size: 1.8rem !important;
    }
    .pg-offers {
      padding-bottom: 4rem;
    //   font-family: $primary_font;
      padding-top: 3.5rem;
      .caption-offers {
          font-size: 2.5rem;
          font-weight: 700;
          color: #000;
      }
      .offers-block {
          height: 100%;
          border: none;
          border-radius: 15px;
          box-shadow: 5px 10px 20px rgba(0, 0, 0, .03);
          padding: 15px 20px;
          border: 1px solid #fff;
          transition: all .3s ease-out;
          &:hover{
            border: 1px solid green;
          }
          .card-title {
          font-size: 1.35rem;
          font-weight: 700;
          line-height: 1.85rem;
          margin-bottom: 25px;

        }
        .text-block {
          font-size: 1rem;
          line-height: 1.75rem;
        }
      }
    }

    @media (min-width: 1200px) {
      .sub-banner-content .sub-heading-sm {
          font-size: 3.75rem;
          font-weight: 800;
      }
      .pg-offers {
          padding-top: 7.5rem;
      }
    }

    @media (min-width: 992px) {
        .sub-banner-content{
            .sub-heading-sm {
              font-size: 3rem;
            }
            .short-note {
              padding: 0 4.5rem;
              font-size: 1.25rem;
              line-height: 1.875rem;
            }
        }
        .service-overview{
            .service-ov__title {
                font-size: 3.125rem;
            }
            .service-ov__desc {
              font-size: 1.25rem;
              line-height: 2.313rem;
            }
        }
        .btn-client-all {
          font-size: 2.188rem;
        }
        .pg-offers {
            padding-bottom: 5.175rem;
            .caption-offers {
              font-size: 3.125rem;
              margin-bottom: 3rem;
            }
            .offers-block {
              padding: 30px 30px 40px;
              .card-title {
                  font-size: 1.875rem;
              }
            }

        }
    }
    @media (min-width: 768px) {
          .btn-client-all {
              font-size: 2rem;
          }
      }
}


//mobile application

.section1-background{
  background-image: url(https://admin.shopersbay.com/assets/uploads/10015/ckeditor/9b1f58d46cf629f761c7dc8fd6f0b2ce.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 120px 0px 120px 100px;
  border-radius: 5px 5px 5px 5px;
  position: relative;
}
.section1-heading h6{
  color: #fff;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}
.section1-quotes h1{
  color: #fff;
  font-weight: bold;
  font-family: Amiri;
  font-size: 48px;
  line-height: 1.2;
}
.section2-ot-heading > span.is_line{
  padding-left: calc(45px + 15px);
}
.section2-ot-heading > span{
  color: #95A2AC;
  border-color: #95A2AC;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.section2-ot-heading > span.is_line:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 50%;
    width: 45px;
    height: 1px;
    background: #95A2AC;
}
.accordion-button{
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, .15);
}
.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #55bb53;
}
.accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}
.accordion-item{
  border: none;
}
.list-icon{
  color: #55bb53;
    font-size: 17px;
    font-weight: 700;
    margin-right: 10px;
    padding: 1px 5px;
}
.service4-top-detail3{
  padding: 70px 0 70px 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  h6{
    color: #FFFFFF;
    font-family: "Inter", Sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  h2{
    color: #FFFFFF;
    font-size: 36px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    line-height: 1.2;
  }
}

@media (max-width: 767px) {
  .section1-background{
    background-position: center left;
    background-size: cover;
    padding: 60px 0px 60px 30px;
  }
  .section1-quotes{
    padding:0px 0px 25px 0px;
  }
  .section1-quotes h1{
    font-size: 30px;
  }
}

#exampleModal{
  .modal-header{
    border-bottom: unset;
  }
  .coporate-form-wrap{
    box-shadow: unset;
    padding: unset;
  }
}
.static-page-list{
    justify-content: center;
    border-bottom: 1px solid #fff6;
    border-top: 1px solid #fff6;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 0;
    .static-page{
      color: #fff;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      padding: 18px 10px 10px 0;
      text-decoration: none;
      &:first-child{
        padding: 18px 10px 10px 0;
      }
      .footer-line{
        padding: 0 10px;
      }
      &:not(:last-child):after {
        content: ""; /* Add this line */
        border-right: 1px solid #fff;
        margin-left: 20px;
      }
    }




}
