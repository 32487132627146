.casestudies-coporate-portfolio{
   
        .projects-box{
            overflow: hidden;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            img{
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
            }
        }
        .casestudies-portfolio-info{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 45px 50px;
            width: 100%;
            z-index: 1;
            h5{
                margin-bottom: 15px;
                font-size: 30px;
                font-weight: 600;
                a{
                    color: #fff;
                }
            }
            .casestudies-portfolio-cates{
                color: #e9e9e9;
                font-family: "Inter", sans-serif;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                a{
                    color: #e9e9e9;
                    line-height: 1;
                    display: inline;
                    color: #e9e9e9;
                    line-height: 1;
                    display: inline;
                    &:hover{
                        background-size: 100% 1px;
                    }
                }
            }
        }
        
   

    
    
    
}
.swiper-pagination-bullet-active{
    background: #55bb53 ;
}
.swiper-pagination{
    position: unset !important;
    margin-top: 20px;
}

 
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-wrapper {
    transition-timing-function: linear;
 }
 .swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    .swiper-slide {
        width: 100%;
    }
  }
  
  
  